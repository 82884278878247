import React, { useState, useEffect } from 'react';
import '../styles/main.scss';
import logo from '../images/logo.png';
import { Link, NavLink } from "react-router-dom";
import axios from 'axios';

const Navbar = () => {
    // const [showHeader, setShowHeader] = useState(true); // Initially show the header
    const [menuOpen, setMenuOpen] = useState(false);
    

    
    useEffect(() => {
      // Function to fetch geolocation data
      const fetchGeolocation = async () => {
        try {
          
          const response = await axios.get('https://ipinfo.io/json?token=0b3addb071c726', );
          const data = {
            ipAddress: response.data.ip,
            city: response.data.city,
            region: response.data.region,
            country: response.data.country,
          };

  
          // Make another request to send the visitor data to your server-side
          await axios.post('https://www.techno-consulting.tn/api/visitors/create', data);
          console.log('Visitor recorded successfully');
        } catch (error) {
          console.error('An error occurred while fetching geolocation data:', error);
        } 
      };
  
      // Use a timer to limit requests to one per second
      const timer = setTimeout(fetchGeolocation, 1000);
  
      // Cleanup the timer if the component unmounts
      return () => clearTimeout(timer);
    }, []);

    return (
        <div>
            {/* {showHeader && ( */}
                <nav>
                    {/* <div className="logo"> */}
                    <Link to="/" className="Home logo">
                        <img src={logo} alt="Logo" />
                    </Link>
                    {/* </div> */}
                    <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <ul className={menuOpen ? "open" : ""}>
                        <li><NavLink to="/" >Accueil</NavLink></li>
                        <li><NavLink to="/about" >À propos</NavLink></li>
                        <li><NavLink to="/services" >Services</NavLink></li>
                        <li><NavLink to="/devis" >Devis</NavLink></li>
                        <li><NavLink to="/contact">Contact</NavLink></li>
                        {/* <li><NavLink to="/login" className="btn btn-dark">Se connecter</NavLink></li> */}
                    </ul>
                </nav>
            {/* )} */}
        </div>
    );
};

export default Navbar;
