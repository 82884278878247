import React, { useState } from 'react';
import ModalForm from '../components/ModalForm.jsx';
import '../styles/Devis.scss'; // Import your SCSS file for styling
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons';
import mern from '../images/MERN.png';
import mean from '../images/mean-stack.png';
import full from '../images/fullstack-dev.jpg';
import data from '../images/data_analysis.png';
import vis from '../images/visulaiser_donnees.png';
import col from '../images/collection.png'

function Devis() {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };


  return (
    <div className='devis_page'>
      <section className="teams-section">
        <div className="container">
          <h2 className="section-title">ACCOMPAGNEMENT & EXTERNALISATION</h2>
          <div className="team-cards">
            <div className="team-card">
              <img src={mern} alt="Team 1" /><br /><br /><br />
              <h3>Développeur MERN Stack à distance </h3>
              <p className='content'>MERN est un acronyme représentant un ensemble de technologies (à la fois côté serveur et côté client) permettant de développer des applications web full stack.</p><br />
              <hr></hr>
              <p align="right" className='ftr'>110  <FontAwesomeIcon icon={faEuroSign} style={{ color: "#0c6917", }} /></p>
            </div>
            <div className="team-card">
              <img src={mean} alt="Team 1" /><br /><br /><br />
              <h3>Développeur MEAN Stack à distance </h3>
              <p className='content'>La  MEAN stack est un framework basé sur JavaScript pour le développement d'applications web. MEAN tire son nom de MongoDB,
               Express, Angular et Node, les quatre technologies clés qui composent les couches de la MEAN stack.</p><br/>
              <hr></hr>
              <p align="right" className='ftr'>110  <FontAwesomeIcon icon={faEuroSign} style={{ color: "#0c6917", }} /></p>
            </div>
            <div className="team-card">
              <img src={full} alt="Team 1" />
              <h3>Développeur Full Stack à distance </h3>
              <p className='content'>Les développeurs web full stack ont la capacité de concevoir des applications web complètes et des sites web. Ils travaillent sur le front-end,
               le back-end, la base de données et le débogage des applications web ou des sites web.</p><br/><br/>
              <hr></hr>
              <p align="right" className='ftr'>160  <FontAwesomeIcon icon={faEuroSign} style={{ color: "#0c6917", }} /></p>
            </div>
          </div>
          <br />
          <div className="team-cards">
            <div className="team-card">
              <img src={col} alt="Team 1" /><br /><br /><br /><br/>
              <h3> La collection des données </h3>
              <p className='content'>La collecte de données à partir de différentes sources implique la collecte systématique d'informations provenant d'une variété d'origines,
               qui peuvent inclure des ressources web, des bases de données, des API, des capteurs etc.voici les outils que nous utilisons :Outils de Web Scraping : (Beautiful Soup, Scrapy...),Outils ETL (Extraction, Transformation, Chargement) : tels que (Apache NiFi, Apache Kafka...),Outils de Requête de Base de Données : tels que SQL Server Management Studio, MySQL Workbench et pgAdmin </p>
                <hr></hr>
              <footer className="bg-light">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <p align="right" className='ftr'>140  <FontAwesomeIcon icon={faEuroSign} style={{ color: "#0c6917", }} /></p>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
            <div className="team-card">
              <img src={data} alt="Team 1" /><br /><br /><br/>
              <h3>Analyse des données </h3>
              <p className='content'>
                L'analyse de données est le processus systématique d'inspection, de nettoyage, de transformation et d'interprétation de données brutes dans le but d'extraire des informations significatives, des motifs et des tendances.
                Voici quelques-uns des outils que nous utilisons (R, Python, Jupyter Notebook, Tableau Public, Google Data Studio...).</p><br /><br />
              <hr></hr>

              <footer className="bg-light">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <p align="right" className='ftr'>140  <FontAwesomeIcon icon={faEuroSign} style={{ color: "#0c6917", }} /></p>
                    </div>
                  </div>
                </div>
              </footer>
            </div>

            <div className="team-card">
              <img src={vis} alt="Team 1" />
              <h3>Visualisation de données </h3>
              <p className='content'>La visualisation des données est un outil puissant pour transmettre efficacement des informations et prendre des décisions basées sur les données. En transformant les données brutes en graphiques visuels.
              Voici quelques-uns des outils que nous utilisons : (Microsoft Power BI Desktop, Tableau Community Edition, Plotly, Chart.js, Tableau Public, Flask, Django ...).</p><br /><br />
              <hr></hr>
              <footer className="bg-light">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <p align="right" className='ftr'>140  <FontAwesomeIcon icon={faEuroSign} style={{ color: "#0c6917", }} /></p>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </section>
      <section className="black-section">
        <div className="center-content">
          <h2 className="section-titlee">Demander un Devis</h2>
          <p className="section-description">Bienvenue chez Techno Consulting, les architectes du futur numérique. En tant qu'entreprise technologique dynamique et innovante, nous combinons la puissance de la créativité et de la technologie de pointe pour transformer les entreprises et les industries. Avec une passion inébranlable pour l'innovation, nous proposons une gamme de services qui stimulent votre réussite à l'ère numérique.</p>
          <button className="section-button" onClick={openModal}>Devis</button>
          <ModalForm show={showModal} onHide={closeModal} />
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Devis;
