import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar';
import ContactPage from './components/ContactPage';
import AboutPage from './components/AboutPage';
import ServicesPage from './components/ServicesPage';
import HomePage from './components/HomePage';
import Devis from './components/Devis';






const App = () => {
  return (
    <BrowserRouter>
      <div>
        <Navbar />
        <Routes>
          <Route path="/"  element={<HomePage/>} />
          <Route path="/devis" element={<Devis/>} />
          <Route path="/services" element={<ServicesPage/>} />
          <Route path="/contact" element={<ContactPage/>} />
          <Route path="/about" element={<AboutPage/>} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
