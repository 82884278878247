import React from 'react';
import '../styles/TechnologyStack.scss'; // Import your CSS file for styling
import android from '../images/android.png';
import react_nativ from '../images/reactnative.png';
import flutter from '../images/flutter.png';
import django from '../images/django.png';
import flusk from '../images/flask.png';
import angular from '../images/angular.png';
import react from '../images/react.png';
import nodejs from '../images/nodejs.png';
import mysql from '../images/mysql.png';
import mongodb from '../images/mongodb.png';
import sqlserver from '../images/sqlserver.png';
import hbase from '../images/hbase.png';
import hive from '../images/hive.png';
import vue from '../images/vuejs.png';
import docker from '../images/docker.png';
import jenkins from '../images/jenkins.png';
import kubernetes from '../images/kubernetes.png';
import spark from '../images/spark.png';
import hadoop from '../images/hadoop.png';
import kafka from '../images/kafka.png';
import kibana from '../images/kibana.png';
import nifi from '../images/nifii.png';
import python from '../images/python.png';
import java from '../images/java.png';

const TechnologyStack = () => {
    return (
        <section className="technology-stack">
            <div className="container">
                <h2>Notre pile Technologique</h2>
                <p>
                Chez Techno Consulting, nous exploitons les dernières technologies pour créer des applications Web de pointe.
                </p>
                <div className="technology-table">
                <div className="table-row">
                        <div className="table-category">Javascript Framwork</div>
                        <div className="technology-icons">
                            <div className="technology-icon">
                                <img src={angular} alt="Angular" />
                            </div>
                            <div className="technology-icon">
                                <img src={react} alt="ReactJs" />
                            </div>
                            <div className="technology-icon">
                                <img src={nodejs} alt="Nodejs" />
                            </div>
                            <div className="technology-icon">
                                <img src={vue} alt="Vuejs" />
                            </div>
                            <div className="technology-icon"></div>
                        </div>
                    </div>
                    <div className="table-row">
                        <div className="table-category">Mobile Development</div>
                        <div className="technology-icons" >

                            <div className="technology-icon">
                                <img src={flutter} alt="Flutter" />
                            </div>
                            <div className="technology-icon">
                                <img src={react_nativ} alt="React Native" />
                            </div>
                            <div className="technology-icon">
                                <img src={android} alt="Android" />
                            </div>
                            <div className="technology-icon"></div>
                            <div className="technology-icon"></div>
                        </div>
                    </div>
                    <div className="table-row">
                        <div className="table-category">Web Technologies</div>
                        <div className="technology-icons">
                            <div className="technology-icon">
                                <img src={django} alt="Django" />
                            </div>
                            <div className="technology-icon">
                                <img src={flusk} alt="Flusk" />
                            </div>
                            <div className="technology-icon">
                            <img src={python} alt="Python" />
                            </div>
                            <div className="technology-icon">
                                <img src={java} alt='Java'/>
                            </div>
                            <div className="technology-icon"></div>
                        </div>
                    </div>
                    
                    <div className="table-row">
                        <div className="table-category">Database</div>
                        <div className="technology-icons">
                            <div className="technology-icon">
                                <img src={mysql} alt="MySQL" />
                            </div>
                            <div className="technology-icon">
                                <img src={mongodb} alt="MongoDb" />
                            </div>
                            <div className="technology-icon">
                                <img src={sqlserver} alt="SQLServer" />
                            </div>
                            <div className="technology-icon">
                                <img src={hbase} alt="Hbase" />
                            </div>
                            <div className="technology-icon">
                                <img src={hive} alt="Hive" />
                            </div>
                        </div>
                    </div>
                    <div className="table-row">
                        <div className="table-category">DevOps</div>
                        <div className="technology-icons">
                            <div className="technology-icon">
                                <img src={docker} alt="Docker" />
                            </div>
                            <div className="technology-icon">
                                <img src={jenkins} alt="Jenkins" />
                            </div>
                            <div className="technology-icon">
                                <img src={kubernetes} alt="Kubernetes" />
                            </div>
                            <div className="technology-icon"></div>
                            <div className="technology-icon"></div>
                        </div>
                    </div>
                    <div className="table-row">
                        <div className="table-category">Big Data</div>
                        <div className="technology-icons">
                        <div className="technology-icon">
                                <img src={hadoop} alt='hadoop'/>
                            </div>
                            <div className="technology-icon">
                                <img src={kibana} alt="Kibana" />
                            </div>
                            <div className="technology-icon">
                                <img src={spark} alt="Spark" />
                            </div>
                            <div className="technology-icon">
                                <img src={kafka} alt="Kafka" />
                            </div>
                            <div className="technology-icon">
                            <img src={nifi} alt="Nifi" />
                            </div>
                        
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TechnologyStack;
