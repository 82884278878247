import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import 'react-phone-input-2/lib/style.css'; // Import the CSS for the phone input
import PhoneInput from 'react-phone-input-2'; // Import the PhoneInput component
import Select from 'react-select'; // Import react-select for country selection
import '../styles/ModalForm.scss';
import SuccessPopup from '../components/SuccessPopup';

const ModalForm = ({ show, onHide }) => {
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [formData, setFormData] = useState({
    nom: '',
    email: '',
    numero_de_telephone: '',
    nom_de_la_societe: '',
    pays: { value: 'TN', label: 'Tunisia' },
    type_de_projet: 'Sélectionner le type',
    message: '',
  });
  const [countryOptions, setCountryOptions] = useState([]); // To store the list of countries

  const handleClosePopup = () => {
    setShowSuccessPopup(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle phone input change
  const handlePhoneChange = (value, country) => {
    setFormData({ ...formData, numero_de_telephone: value });
  };

  // Handle country select change
  const handleCountryChange = (selectedOption) => {
    setFormData({ ...formData, pays: selectedOption?.value });
  };


  useEffect(() => {
    // Fetch the list of countries from an API
    axios
      .get('https://restcountries.com/v3.1/all')
      .then((response) => {
        const countries = response.data.map((country) => ({
          value: country.name.common,
          label: country.name.common,
        }));

        setCountryOptions(countries);
      })
      .catch((error) => {
        console.error('Failed to fetch country data:', error);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send form data to the server
      const response = await axios.post('https://www.techno-consulting.tn/api/demande/create', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        // Handle success, maybe show a success message to the user
        console.log('Demande_D submitted successfully');
        setFormData({
          nom: '',
          email: '',
          numero_de_telephone: '',
          nom_de_la_societe: '',
          pays: { value: 'TN', label: 'Tunisia' }, // Set it to the default value
          type_de_projet: 'Sélectionner le type',
          message: '',
        });
        onHide(); // Close the modal after
        setShowSuccessPopup(true); // Show success popup
      } else {
        // Handle error, show an error message to the user

        console.error('Failed to submit contact information');
      }
    } catch (error) {
      // Handle error, show an error message to the user
      console.error('Failed to submit Demande_D', error);
    }
  };

  return (
    <div>
      <Modal show={show} onHide={onHide} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Devis</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group >
              <Form.Label htmlFor="nom">Nom</Form.Label>
              <Form.Control
                type="text"
                placeholder="Donnez votre nom complet"
                id="nom"
                name="nom"
                value={formData.nom}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group >
              <Form.Label htmlFor="email">Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Veuillez fournir votre adresse e-mail"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group >
              <Form.Label htmlFor="numero_de_telephone">Téléphone</Form.Label>
              <PhoneInput
                country={'tn'}
                inputProps={{
                  name: 'numero_de_telephone',
                  required: true,
                  id: 'numero_de_telephone',
                  placeholder: 'Veuillez fournir votre numéro de téléphone',
                }}
                onChange={(value, country) => handlePhoneChange(value, country)}
              />
            </Form.Group>

            <Form.Group >
              <Form.Label htmlFor="nom_de_la_societe">Nom de la société</Form.Label>
              <Form.Control
                type="text"
                placeholder="Veuillez donner le nom de votre société"
                id="nom_de_la_societe"
                name="nom_de_la_societe"
                value={formData.nom_de_la_societe}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group >
              <Form.Label htmlFor="pays">Pays</Form.Label>
              {/* Use react-select for the country input */}
              <Select
                name='pays'
                inputId="pays"
                placeholder="Sélectionnez le pays"
                value={formData.selectedCountry}
                options={countryOptions}
                onChange={handleCountryChange}
                isSearchable={true}
                required
              />
            </Form.Group>

            <Form.Group >
              <Form.Label htmlFor="type_de_projet">Type</Form.Label>
              <Form.Control
                as="select"
                id="type_de_projet"
                name="type_de_projet"
                value={formData.type_de_projet}
                onChange={handleChange}
                required
              >
                <option>Sélectionner le type</option>
                <option>Creation Application web et mobile</option>
                <option>Maintenance technique</option>
                <option>Nom de domaine et Hébergement web</option>
                <option>Sous-traitance web</option>
                <option>Partage salarial</option>
                <option>Autre</option>
              </Form.Control>
            </Form.Group>

            <Form.Group >
              <Form.Label htmlFor="message">Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Écrivez votre message"
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
              />
            </Form.Group>

            <Modal.Footer>
              <Button variant="secondary" onClick={onHide}>
                Fermer
              </Button>
              <Button variant="primary" type="submit">
                Envoyer
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      {showSuccessPopup && <SuccessPopup onClose={handleClosePopup} />}
    </div >
  );
};

export default ModalForm;
