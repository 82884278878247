import React, { useState, useEffect } from 'react';
import Select from 'react-select'; // Import react-select
import '../styles/ContactCardForm.scss';
import SuccessPopup from '../components/SuccessPopup';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2'; // Import the phone input component
import 'react-phone-input-2/lib/style.css'; // Import the styles for the phone input


const ContactCardForm = () => {
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null); // For storing the selected country
  const [countryOptions, setCountryOptions] = useState([]); // To store the list of countries

  useEffect(() => {
    // Fetch the list of countries from the API
    axios
      .get('https://restcountries.com/v3.1/all')
      .then((response) => {
        const countries = response.data.map((country) => ({
          value: country.name.common,
          label: country.name.common,
        }));
        countries.sort((a, b)=>a.label.localeCompare(b.label));
        setCountryOptions(countries);
      })
      .catch((error) => {
        console.error('Failed to fetch country data:', error);
      });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      name,
      email,
      phone,
      message,
      pays: selectedCountry?.value, // Use the selected country value
    };

    try {
      // Send form data to the server
      const response = await axios.post('https://www.techno-consulting.tn/api/contact/create', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        // Handle success, maybe show a success message to the user
        console.log('Contact information submitted successfully');
        setShowSuccessPopup(true);
        // Clear form fields
        setName('');
        setEmail('');
        setPhone('');
        setMessage('');
        setSelectedCountry(null); // Clear the selected country
      } else {
        // Handle error, show an error message to the user
        console.error('Failed to submit contact information');
      }
    } catch (error) {
      console.error('An error occurred while submitting contact information', error);
    }
  };

  const handleClosePopup = () => {
    setShowSuccessPopup(false);
  };

  return (
    <div>
      <form className="contact-card-form" onSubmit={handleSubmit}>
        <h2>Contactez-nous</h2>
        <div className="form-group">
          <input
            type="text"
            className="form-control form-control-lg"
            id="name"
            placeholder="Nom complet"
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoComplete="on"
            required
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            className="form-control form-control-lg"
            id="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="on"
            required
          />
        </div>
        <div className="form-group">
                    <PhoneInput
                        inputProps={{
                            name: 'phone',
                            required: true,
                        }}
                        country={'tn'} // Set default country (you can change it)
                        value={phone}
                        onChange={(phone) => setPhone(phone)} // Update 'phone' state when phone number changes
                    />
                </div>
        <div className="form-group">
          {/* Use react-select for the country input */}
          <Select
            id="pays"
            placeholder="Sélectionnez le pays"
            value={selectedCountry}
            options={countryOptions}
            onChange={(selectedOption) => setSelectedCountry(selectedOption)}
            isSearchable={true}
            
            required
          />
        </div>

        <div className="form-group">
          <textarea
            className="form-control form-control-lg"
            id="mess"
            placeholder="Message"
            value={message}
            autoComplete="on"
            onChange={(e) => setMessage(e.target.value)}
            required
            rows="4"
          />
        </div>
        <button type="submit" className="btn btn-warning btn-lg">
         <strong>Envoyer</strong> 
        </button>
      </form>
      {showSuccessPopup && <SuccessPopup onClose={handleClosePopup} />}
    </div>
  );
};

export default ContactCardForm;
