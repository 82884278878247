import React from 'react';
import ReactCardFlip from 'react-card-flip';
import 'bootstrap/dist/css/bootstrap.min.css';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import logoImage from '../images/soutretance.png';


const CardFlip = () => {
    // const [isFlipped, setIsFlipped] = useState(false);

    // const handleClick = (e) => {
    //     e.preventDefault();
    //     setIsFlipped(prevIsFlipped => !prevIsFlipped);
    // };

    return (
        // <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        <ReactCardFlip >

            {/* <div className="col-md-8 mx-auto cardf">
                <div className="content" style={{ textAlign: 'justify' }}>
                    <Card >
                        <CardMedia
                            component="img"
                            alt="green iguana"
                            height="500"
                            image={logoImage}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h2" component="div">
                                Les Avis
                            </Typography>
                            <Typography variant="h5" color="text.primary">
                                Techno Consulting lance un appel aux entreprises nationales et internationales, qu'elles soient anglophones ou francophones, pour profiter de nos services de sous-traitance contractuelle. Nous offrons des solutions complètes ou partielles dans les domaines de l'informatique et de l'intelligence artificielle, incluant le codage en langages tels que (Java, Jee, Python, Django, React JS et Node Js) entre autres. Notre équipe hautement expérimentée est prête à vous accompagner dans votre projet, peu importe votre localisation. Avec une approche axée sur l'excellence, nous fournissons des services de qualité supérieure à des tarifs compétitifs. Pour obtenir de plus amples informations, n'hésitez pas à nous contacter :<br/>
                                <strong> Email : contact@techno-consulting.tn<br/>
                                Téléphone : +216 28 808 223.</strong><br />
                                <p align="right"><button onClick={handleClick} className="btn btn-lg btn-warning btn-lg "><strong>En Englais</strong></button></p>

                            </Typography>
                        </CardContent>
                    </Card>
                </div>
            </div> */}
            

            <div className="col-md-8 mx-auto cardf">
                <div className="content" style={{ textAlign: 'justify' }}>
                    <Card >
                        <CardMedia
                            component="img"
                            alt="green iguana"
                            height="500"
                            image={logoImage}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h2" component="div">
                                Reviews
                            </Typography>
                            <Typography variant="h5" color="text.primary">
                                Techno Consulting extends an invitation to national and international enterprises, both English-speaking and French-speaking, to benefit from our contractual outsourcing services. We offer comprehensive or partial solutions in the fields of IT and artificial intelligence, encompassing coding in languages such as (Java, JEE, Python, Django, React JS, and Node.js), among others. Our highly experienced team is poised to guide you in your project, regardless of your location. With a focus on excellence, we provide top-quality services at competitive rates. For further information, please don't hesitate to contact us:<br/>
                                <strong> Email: contact@techno-consulting.tn<br/>
                                Phone: +216 28 808 223.</strong><br />
                                {/* <p align="right"><button onClick={handleClick} className="btn btn-warning btn-lg"><strong>En Français</strong></button></p> */}

                            </Typography>
                        </CardContent>
                    </Card>
                </div>
            </div>
            <div></div>
        </ReactCardFlip>
    );
};

export default CardFlip;
