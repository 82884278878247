// SuccessPopup.js
import React from 'react';
import '../styles/SuccessPopup.scss'; // Create this file for styles

const SuccessPopup = ({ onClose }) => {
  return (
    <div className="success-popup">
      <div className="popup-content">
        <h2>Succès!</h2>
        <h4 style={{ color: 'black'}}>Votre message a été envoyé avec succès.</h4>
        <button className='btn btn-success' onClick={onClose}>Fermer</button>
      </div>
    </div>
  );
};

export default SuccessPopup;
