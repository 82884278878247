// Footer.jsx
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import '../styles/Footer.scss';


const Footer = () => {
    const [showFooter, setShowFooter] = useState(false);
    useEffect(() => {
        // Function to handle scroll event
        const handleScroll = () => {
            // Calculate the scroll position
            const scrollY = window.scrollY || document.documentElement.scrollTop;

            // Calculate the page height
            const pageHeight = document.documentElement.scrollHeight - window.innerHeight;

            // Determine whether to show or hide the footer
            if (scrollY >= pageHeight) {
                setShowFooter(true);
            } else {
                setShowFooter(false);
            }
        };

        // Add a scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div>
            {showFooter && (
                <footer className="footer">
                    <div className="contact-info social-icons">
                        <p>Contactez-nous à :</p>
                        <p>Email: contact@techno-consulting.tn</p>
                        <p>Téléphone: +216 28 808 223</p>
                        <a href="https://www.linkedin.com/in/techno-consulting" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>
                        <a href='https://www.facebook.com/profile.php?id=61550575203799' target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebook} /></a>
                        <a href='https://www.instagram.com/techno.consulting.tn/' target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
                        <a href='https://twitter.com/Technconsulting' target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /></a>
                    </div>
                    <div className="">

                    </div>
                </footer>
            )}
        </div>
    );
};

export default Footer;
