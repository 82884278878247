import '../styles/ContactPage.scss';
// import logoImage from '../images/logobg.png';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { MapContainer } from 'react-leaflet';
import React from 'react';
import 'leaflet/dist/leaflet.css';
import Footer from '../components/Footer';
import ContactCardForm from './ContactCardForm';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


const ContactPage = () => {

    const mapCenter = [36.46265603684425, 10.748585116728467];




    //const mapCenter = { lat: 36.462672, lng: 10.748827 };36.46265603684425, 10.748585116728467
    return (
        <div className="contact-page" style={{ paddingTop: '30px'}}>

            <div className="container col-md-6" >
                {/* <div className="row">
                        <div className="col-lg-10 mb-4">
                            <div className="card">
                                <center>
                                    <div className="card-body">
                                        <h1 className="card-title">Contact Techno Consulting</h1>
                                        <p className="card-text">
                                        Nous sommes situés à Nabeul, Dar Chaabane El Fehri. N'hésitez pas à nous contacter pour toute demande ou information.
                                        </p>
                                        <h1 className="text-light-custom">Contact Details:</h1>
                                        <p className="text-light-custom-secondary card-text"><strong>Company Name:</strong> Techno Consulting</p>
                                        <p className="text-light-custom-secondary card-text"><strong>Location:</strong> Nabeul, Dar Chaabane El Fehri</p>
                                        <p className="text-light-custom-secondary card-text"><strong>Email:</strong> info@technoconsulting.com</p>
                                        <p className="text-light-custom-secondary card-text"><strong>Phone:</strong> +1234567890</p>
                                    </div>
                                </center>
                            </div>
                        </div>
                    </div> */}
                <Card >

                    <CardContent>
                        <Typography gutterBottom variant="h2" component="div" style={{ color: '#269e98' }}>
                            Contact Techno Consulting
                        </Typography>
                        <Typography variant="h5" color="text.primary">
                            <strong>Nom de l'entreprise: </strong>Techno Consulting <br />

                            <strong>Adresse 1:</strong>Dar chaabane El fehri, Nabeul.<br />
                            <strong>Adresse 2:</strong> Institut Supérieur des Etudes Technologiques de Zaghouan   <br />

                            <strong>Téléphone:  </strong>+216 28 808 223 <br/>
                            <strong>Email:  </strong>contact@techno-consulting.tn

                        </Typography>
                    </CardContent>

                </Card>
                <br />
                <div className="row">
                    <div className="contact-form-container">
                        <ContactCardForm />
                    </div>
                </div>
            </div>

            <div className="map-container" >
                <MapContainer center={mapCenter} zoom={30} style={{ width: '100%', height: '500px', margin: '0 auto' }}>
                    {/* <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Marker position={mapCenter}>
                        <Popup>Techno Consulting</Popup>
                    </Marker> */}
                    <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d802.1973343867371!2d10.748162269573664!3d36.46264699827159!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzbCsDI3JzQ1LjUiTiAxMMKwNDQnNTUuNyJF!5e0!3m2!1sfr!2stn!4v1693414995460!5m2!1sfr!2stn" style={{ width: '100%', height: '500px', margin: '0 auto' }} ></iframe>
                </MapContainer>
                <br />
                <br />
                <br />
                <br />
            </div>
            <Footer />
        </div>

    );
};
export default ContactPage;
