// import React,{ useEffect } from 'react';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal, faWandMagicSparkles, faArrowsDownToPeople, faUserTie } from '@fortawesome/free-solid-svg-icons';
import '../styles/HomePage.scss';
import Footer from './Footer';
import home from '../images/home1.png';
import homes from '../images/home2.png';
import homet from '../images/transform.png';
import CardFlip from '../components/CardFlip';
// import axios from 'axios';

// import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

const HomePage = () => {
    // useEffect(() => {
    //     axios.post('/record-visitor');
    //   }, []);
    
    return (
        <div style={{ backgroundColor: '#f7f7f7' }}>
            <div>
                <div className="home">
                    <div className="features text-center"> {/* Apply text-center class */}
                        <div className="feature">
                            <span className="feature-title">Techno Consulting : Pionnier de l'Innovation Technologique</span>
                            <p className="feature-description">
                                Bienvenue chez <span style={{ color: '#269e98' }}><strong>Techno Consulting</strong></span>, là où la créativité et la technologie se rejoignent pour façonner l'avenir numérique. <br />
                                Une startup dynamique enracinée dans l'environnement académique de l'Institut Supérieur des Études Technologiques de Zaghouan, <br />
                                offrant des solutions informatiques innovantes et des produits de pointe.
                            </p>
                            <img src={home} alt="Feature 1" className="feature-image" />

                        </div>
                        {/* Repeat similar code for other features */}
                    </div>
                </div>
                {/* <AliceCarousel infinite={true} autoPlay={true}  autoPlayInterval="2000">
                    <img src={Data1} className="sliderimg" alt='data visualisation' />
                    <img src={Data2} className="sliderimg" alt='data visualisation'/>
                    <img src={Data3} className="sliderimg" alt='data visualisation' />
                    <img src={Data4} className="sliderimg" alt='data visualisation'/>
                </AliceCarousel> */}
            </div>
            {/* Value for Clients Section */}
            <div >
                <section className="value-section" >
                    <div className="containerT" >

                        <h2 className='title'>Nos valeurs</h2>


                    </div>
                    <section className="boxes-section py-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3 mb-4">
                                    <div className="box p-4 text-center">
                                        <div className="box-icon">
                                            <FontAwesomeIcon icon={faMedal} size="3x" className="text-primary mb-3" />
                                        </div>
                                        <h3>Quality</h3>
                                        <p>Notre qualité est notre signature. Chaque solution que nous proposons, chaque produit que nous créons, est le fruit d'un engagement indéfectible envers l'excellence.</p>
                                    </div>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <div className="box p-4 text-center">
                                        <div className="box-icon">
                                            <FontAwesomeIcon icon={faWandMagicSparkles} size="3x" className="text-primary mb-3" />
                                        </div>
                                        <h3>l'esprit d'innovation</h3>
                                        <p>En embrassant l'innovation, nous recherchons continuellement des solutions créatives qui repoussent les limites de ce qui est possible dans notre industrie.</p>
                                    </div>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <div className="box p-4 text-center">
                                        <div className="box-icon">
                                            <FontAwesomeIcon icon={faUserTie} size="3x" className="text-primary mb-3" />
                                        </div>
                                        <h3>Responsabilité et Engagement :</h3>
                                        <p>Nous assumons pleinement la responsabilité de notre travail, veillant à tenir nos promesses et à assumer la responsabilité de nos actions.</p>
                                    </div>
                                </div>
                                <div className="col-md-3 mb-4">
                                    <div className="box p-4 text-center">
                                        <div className="box-icon">
                                            <FontAwesomeIcon icon={faArrowsDownToPeople} size="3x" className="text-primary mb-3" />
                                        </div>
                                        <h3>Approche axée sur le client :</h3>
                                        <p>Nos clients sont au cœur de toutes nos actions. Nous nous engageons à comprendre leurs besoins et à fournir des solutions personnalisées.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                </section>

                <section className="revolution-section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h2 className="revolution-title">Révolutionnant la Technologie: Transformer les défis en solutions</h2>
                                <p className="revolution-description" style={{ textAlign: 'justify' }}>
                                    Dans le paysage en constante évolution de la technologie, l'entreprise <span style={{ color: '#269e98' }}><strong>Techno Consulting</strong></span> se distingue en tant que pionnière de l'innovation, ayant pour mission de transformer les défis en solutions concrètes. En combinant l'expertise du développement web avec la gestion ingénieuse des données massives, Techno Consulting redéfinit la manière dont les entreprises abordent les problèmes complexes.<br />
                                    Au cœur de la philosophie de <span style={{ color: '#269e98' }}><strong>Techno Consulting</strong></span> réside une approche centrée sur le client. En comprenant profondément les besoins et les aspirations de nos clients, nous sommes en mesure de concevoir des solutions sur mesure qui répondent directement à leurs défis uniques. Notre équipe de professionnels chevronnés est engagée à fournir des résultats de qualité supérieure, en mettant l'accent sur l'efficacité, la fiabilité et la satisfaction du client.
                                </p>
                                {/* <button className="btn btn-primary revolution-btn">
                                    Learn More
                                </button> */}
                            </div>
                            <div className="col-md-6">
                                <img
                                    src={homes}
                                    alt="Revolutionizing Technology"
                                    className="revolution-image"
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="transforming-challenges-section">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <img
                                    src={homet}
                                    alt="Transforming Challenges"
                                    className="challenges-image"
                                />
                            </div>
                            <div className="col-md-6">
                                <h2 className="challenges-title">De la Collecte de Données à l'Analyse et à la Visualisation : L'Expertise de Techno Consulting</h2>
                                <p className="challenges-description" style={{ textAlign: 'justify' }}>
                                    Dans le monde moderne, les données sont essentielles pour prendre des décisions éclairées en affaires. De la collecte à la visualisation, <span style={{ color: '#269e98' }}><strong>Techno Consulting</strong></span> vous guide dans ce processus crucial.

                                    <br /><strong>Collecte Précise :</strong> Tout commence par la collecte de données pertinentes et fiables à partir de diverses sources.

                                    <br /><strong>Analyse Profonde :</strong> Nous analysons ces données avec expertise pour trouver des tendances, des modèles et des idées précieuses.

                                    <br /><strong>Visualisation Claire :</strong> Nos visualisations attrayantes rendent les données complexes compréhensibles pour des décisions plus éclairées.

                                    <br /><strong>Partenaire Futur :</strong> Avec une équipe formée et des technologies de pointe, nous sommes votre partenaire pour naviguer dans l'univers des données en constante évolution.

                                    <br />En travaillant avec <span style={{ color: '#269e98' }}><strong>Techno Consulting</strong></span>, transformez vos données en un atout pour une meilleure prise de décision.

                                </p>

                            </div>
                        </div>
                    </div>
                </section>
                <CardFlip />
                <br /><br /><br /><br />
            </div>
           
                <Footer/>
        
        </div>
    );
};

export default HomePage;
