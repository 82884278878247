import React from 'react';
import '../styles/AboutPage.scss';
import logoImage from '../images/logobg.png';
import datavis from '../images/datavis.png';
import mern from '../images/MERN.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './Footer';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
// import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import mongo from '../images/mongo.png';
// import Hbase from '../images/hbase.png';
// import Nifi from '../images/nifi.png';
// import ex from '../images/express.png';
// import reactLogo from '../images/react-logo.png';
// import nodejsLogo from '../images/nodejs-logo.png';
// import powerBiLogo from '../images/powerbi-logo.png';
import TechnologyStack from './TechnologyStack';

const AboutPage = () => {

    return (
        <div>
            <div className="about-page" >
                <div className="row">
                    <div className="col-md-4 mx-auto">
                        <div className="content" style={{ textAlign: 'justify' }}>
                            <Card sx={{ maxWidth: 1000 }}>
                                <CardMedia
                                    component="img"
                                    alt="green iguana"
                                    height="300"
                                    image={datavis}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h4" component="div">
                                        Qu’est-ce que la visualisation des données ?
                                    </Typography>
                                    <Typography variant="h5" color="text.secondary">
                                        La visualisation des données (ou data visualization ou dataviz en anglais) désigne le fait de représenter visuellement ses data pour pouvoir déceler et comprendre des informations, les données brutes étant difficilement interprétables et exploitables.<br /> Ce processus se fait par des outils analytiques spécifiques et se matérialise par des tableaux (type Excel), des graphiques, des cartes visuelles ou même des infographies regroupées dans des dashboards (tableaux de bord).
                                    </Typography>
                                </CardContent>
                            </Card>
                            <br />
                            {/* <div className="technology-icons" style={{marginLeft:'40px'}}>
                                <img src={powerBiLogo} alt="Power BI" />
                                <img src={mongo} alt="MongoDB" />
                                <img src={Hbase} alt="Hbase" />
                                <img src={Nifi} alt="Nifi" />
                            </div> */}
                        </div>
                    </div>
                    <div className="col-md-4 mx-auto">
                        <div className="content" style={{ textAlign: 'justify' }}>
                            <Card sx={{ maxWidth: 1000 }}>
                                <CardMedia
                                    component="img"
                                    alt="green iguana"
                                    height="140"
                                    image={logoImage}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h2" component="div">
                                        À propos de nous
                                    </Typography>
                                    <Typography variant="h5" color="text.primary">
                                        Bienvenue chez <span style={{ color: '#269e98' }}>Techno Consulting</span>,
                                        une société enracinée à l'université Institut Supérieur des Etudes Technologiques de Zaghouan.
                                        Fondée en 2022 par une équipe expérimentée, nous sommes une start-up évolutive en informatique et technologies.
                                        Notre parcours reflète un engagement envers l'innovation, avec des services variés et produits pointus,
                                        repoussant les limites technologiques. Forts de notre expertise en IT, nous transformons les défis en opportunités,
                                        offrant des solutions sur mesure pour nos clients. Explorez notre monde alliant créativité et technologie pour façonner l'avenir numérique.
                                        <br />
                                        <br />
                                        <br />
                                    </Typography>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                    <div className="col-md-4 mx-auto">
                        <div className="content" style={{ textAlign: 'justify' }} >
                            <Card sx={{ maxWidth: 1000 }}>
                                <CardMedia
                                    component="img"
                                    alt="green iguana"
                                    height="300"
                                    image={mern}
                                    sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h4" component="div">
                                        Qu’est-ce que MERN Stack ?
                                    </Typography>
                                    <Typography variant="h5" color="text.secondary">
                                        MERN Stack est un ensemble de technologies basées sur JavaScript qui permettent la création et le déploiement d’applications web dynamiques. MERN est l’acronyme de MongoDB, Express.js, ReactJS et Node. Il utilise JavaScript à la fois sur le front-end et le back-end, ce qui le rend extrêmement flexible pour tout type de projet web.
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                    </Typography>
                                </CardContent>
                            </Card>
                            <br />
                            {/* <div className="technology-icons" style={{marginLeft:'40px'}}>
                                <img src={mongo} alt="MongoDB" />
                                <img src={ex} alt="ExpressJs" />
                                <img src={reactLogo} alt="ReactJs" />
                                <img src={nodejsLogo} alt="NodeJs" />
                            </div> */}
                            {/* <Card sx={{ display: 'flex' }} style={{ backgroundColor: '#269e98' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography component="div" variant="h5">
                                            Quel est le principe d'une régression linéaire ?
                                        </Typography>
                                        <Typography variant="subtitle1" color="text.secondary" component="div">
                                            La régression linéaire consiste à tracer une droite au plus proche des points quand ces derniers forment un nuage avec une forme allongé et suggèrent une relation statistique explicative (voir graphique ci-dessous).  La variable peut se traduire par un pourcentage. Exemple : le nombre de fumeurs en fonction du nombre d'années écoulés.
                                        </Typography>
                                    </CardContent>
                                </Box>
                                <CardMedia
                                    component="img"
                                    sx={{ width: 200 }}
                                    // image={reglineaire}
                                    alt="Live from space album cover"
                                />
                            </Card> */}
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <br />
                <TechnologyStack />
                <br />
                <br />
                <br />
                <br />
                <br />

            </div>
            <Footer />
        </div >
    );
};

export default AboutPage;