import React from 'react';
import '../styles/ServicesPage.scss';
import maintenanceIcon from '../images/maintenance-icon.png'; // Replace with your icon paths
import cameraIcon from '../images/camera-icon.png';
import webDevIcon from '../images/webdev-icon.png';
import reactLogo from '../images/react-logo.png';
import nodejs from '../images/nodejs.png';
import powerBiLogo from '../images/powerbi-logo.png';
import flaskLogo from '../images/flask-logo.png';
import mongodb from '../images/mongodb.png';
import Hbase from '../images/hbase.png';
import Nifi from '../images/nifi.png';
import datav from '../images/datav.png';
import Footer from './Footer';

const ServicesPage = () => {

  return (
    <div style={{ backgroundColor: '#f7f7f7', paddingTop: '30px'}} className='services'>

      <div className="container ">
        <h1><center>Nos Services</center></h1><br />
        <div className="row">
          <div className="col-md-6 mb-4">
            <div className="service-card">
              <img src={maintenanceIcon} alt="Maintenance" />
              <h3>Service de Maintenance Personnalisée </h3><br />
              <p>Plongez dans un monde sans tracas avec notre service de maintenance sur mesure chez Techno Consulting. Nous sommes là pour prendre soin de vos besoins en support logiciel et matériel, directement depuis le confort de votre domicile. Que ce soit pour résoudre des problèmes techniques, optimiser les performances de votre équipement ou assurer des mises à jour essentielles, notre équipe d'experts est prête à répondre à l'appel.
                Notre approche personnalisée garantit que chaque intervention est adaptée à vos besoins spécifiques. Nous fournissons également des équipements de pointe pour répondre à toutes vos exigences. Naviguez en toute confiance dans le monde numérique en sachant que nous sommes là pour vous soutenir à chaque étape.
                Pour planifier une visite ou obtenir de plus amples informations sur notre gamme de services, n'hésitez pas à nous contacter au [numéro de téléphone] ou à nous écrire à [adresse e-mail]. Chez Techno Consulting, votre satisfaction est notre priorité absolue, et nous sommes impatients de vous fournir un support exceptionnel et sur mesure.
              </p><br/><br/>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="service-card">
              <img src={cameraIcon} alt="Camera de Surveillance" />
              <h3>Surveillance Évoluée : Découvrez Nos Solutions de Caméras Intelligentes avec IA"</h3>
              <p>Explorez une nouvelle ère de surveillance intelligente avec notre service de caméras dotées de l'intelligence artificielle (IA). Nos caméras intelligentes révolutionnent la manière dont nous surveillons et gérons les espaces en combinant des capacités de pointe avec l'IA pour offrir une expérience de surveillance inégalée. Grâce à des fonctionnalités telles que la détection automatique d'objets, la reconnaissance faciale et la classification des événements, nos caméras IA vous permettent de détecter rapidement les situations critiques et de prendre des mesures proactives.
                Notre technologie d'IA avancée permet à nos caméras de s'adapter aux scénarios changeants, garantissant une précision et une pertinence continues. Que ce soit pour la sécurité de votre entreprise, la gestion du trafic dans des espaces publics ou la surveillance de votre domicile, nos caméras intelligentes fournissent des informations en temps réel pour des décisions éclairées. Plongez dans l'avenir de la surveillance avec nos solutions de caméras intelligentes IA et découvrez comment elles peuvent améliorer la sécurité, la gestion et la tranquillité d'esprit au quotidien.
              </p>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="service-card">
              <img src={datav} alt="Power BI" />
              <h3>Service de Traitement de Données Big Data </h3>
              <p>Notre service de traitement de données Big Data repose sur des frameworks open source de pointe, offrant des solutions puissantes et flexibles pour gérer, analyser et exploiter efficacement les volumes massifs de données. En utilisant des frameworks renommés tels que Apache Hadoop, Apache Spark et Apache Flink, nous avons la capacité de traiter des données complexes à grande échelle tout en assurant des performances optimales.
                Avec Apache Hadoop, nous pouvons distribuer et traiter des données sur des clusters de machines, permettant une scalabilité sans précédent. Apache Spark, quant à lui, offre une vitesse de traitement inégalée grâce à son modèle de calcul en mémoire, rendant les analyses avancées plus rapides et plus accessibles. En intégrant Apache Flink, nous apportons des capacités de traitement en temps réel, idéales pour les applications qui nécessitent une réactivité instantanée.
                Notre équipe d'experts en Big Data est compétente dans l'utilisation de ces frameworks open source et peut les adapter en fonction de vos besoins spécifiques. Que vous ayez des données structurées ou non structurées, que vous cherchiez à extraire des informations précieuses pour des prises de décision éclairées ou à mettre en place des flux de traitement en temps réel, notre service de traitement de données Big Data vous permettra d'exploiter pleinement le potentiel de vos données sans compromis sur la qualité, la sécurité et la performance.
              </p>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="service-card">
              <img src={webDevIcon} alt="Web Development" /><br/>
              <h3>Web Development</h3>
              <p>Chez Techno Consulting, nous sommes fiers d'être votre partenaire de confiance pour tous vos besoins en développement web. Notre équipe expérimentée et passionnée de développeurs travaille en étroite collaboration avec vous pour transformer vos idées en réalité numérique. Que vous recherchiez la création d'un site web élégant, d'une application web interactive ou d'une plateforme de commerce électronique robuste, nous mettons en œuvre les dernières technologies et les meilleures pratiques pour atteindre vos objectifs.
                Notre approche centrée sur le client nous distingue, car nous comprenons que chaque projet est unique. Nous écoutons attentivement vos besoins, vos spécifications et vos préférences pour créer des solutions sur mesure qui correspondent parfaitement à votre vision. Que ce soit en utilisant des frameworks populaires comme React, Angular ou Vue.js, en intégrant des bases de données performantes telles que MySQL, PostgreSQL ou MongoDB, ou en développant des API solides avec Node.js ou Django, nous avons l'expertise nécessaire pour mener à bien votre projet avec succès.
                Votre satisfaction est notre priorité absolue, c'est pourquoi nous nous engageons à livrer des projets de développement web de haute qualité, respectant les délais et dépassant vos attentes. Rejoignez-nous chez HNO Consulting et découvrez comment nous pouvons transformer vos idées en une présence en ligne percutante et efficace.
              </p><br/>
            </div>
          </div>
        </div>

        <div className="technology-section">
          <h2>Notre Technologie</h2>
          <div className="technology-icons">
            <img src={reactLogo} alt="React" />
            <img src={nodejs} alt="Node.js" />
            <img src={powerBiLogo} alt="Power BI" />
            <img src={flaskLogo} alt="Flask" />
            <img src={mongodb} alt="MongoDB" />
            <img src={Hbase} alt="Hbase" />
            <img src={Nifi} alt="Nifi" />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>

      </div>
        <Footer />
    </div>
  );
};

export default ServicesPage;
